<template>
  <div>
    <div class="head-container filter-item" v-if="editable">
      <sku-selector request-url="api/goods/sku" button-text="添加商品" :button-loading="saving" @submit="handleGoodsSet" />&nbsp;
      <el-button type="danger" :loading="deling" :disabled="!selection || !selection.length" @click="handleGoodsRemove(null)">删除勾选的商品</el-button>
      <!-- <div style="margin-left:20px">
        <el-checkbox v-model="isValid">有效期</el-checkbox>
        &nbsp;
        <el-date-picker v-if="isValid" v-model="validDate" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
      </div>-->
    </div>
    <!-- 弹框 -->
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="授权时限" width="500px" @closed="form=null">
      <template v-if="form">
        <el-form ref="form" :model="form"  label-position="right" label-width="84px" label-suffix=":">
          <el-form-item prop="begAt" label="开始日期">
            <el-date-picker type="datetime" v-model="form.begAt" value-format="timestamp" placeholder="开始日期" style="width: 130px" default-time="00:00:00"/>
          </el-form-item>
          <el-form-item prop="endAt" label="结束日期">
            <el-date-picker type="datetime" v-model="form.endAt" value-format="timestamp" placeholder="结束日期" default-time="23:59:59" style="width: 130px" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button type="primary" :loading="subLoading" @click="doSubmit">确认</el-button>
        </div>
      </template>
    </el-dialog>

    <el-table ref="list" v-loading="loading" row-key="id" :data="data" size="small" @selection-change="handleSelectionChange" border>
      <el-table-column type="selection" width="35" reserve-selection fixed />
      <!-- <el-table-column prop="code" label="商品编码" min-width="130" /> -->
      <el-table-column prop="erpCode" label="ERP编码" width="150" />
      <el-table-column prop="name" label="商品名称" min-width="200" show-overflow-tooltip />
      <el-table-column prop="specs" label="规格" min-width="150" :formatter="$goodsSpecConvert" />
      <el-table-column  label="授权时限" width="160" :formatter="r=>{return r.begAt?new Date(r.begAt).format('yyyy/MM/dd')+'至'+new Date(r.endAt).format('yyyy/MM/dd'):'--'}" />
      <el-table-column width="110">
        <div class="row-commands" slot-scope="scope">
          <el-button type="text" size="mini" class="danger" @click="handleEdit(scope.row)">授权时限</el-button>
          <el-button type="text" size="mini" class="danger" @click="handleGoodsRemove(scope.row)">删除</el-button>
        </div>
      </el-table-column>
    </el-table>

    <div class="h c padding-10-0" style="border-bottom: #EBEEF5 solid 1px;">
      <div class="flex"></div>
      <div>统一设置授权时限：</div>
      <div class="c h">
        <el-date-picker type="datetime" size="mini" v-model="setTotal.begAt" value-format="timestamp" placeholder="开始日期" style="width: 125px" default-time="00:00:00"/>&nbsp;
        <el-date-picker type="datetime" size="mini" v-model="setTotal.endAt" value-format="timestamp" placeholder="结束日期" style="width: 125px" default-time="23:59:59"/>&nbsp;
      </div>
      <el-button type="primary" size="mini" @click="handleUse()" :disabled="!(setTotal.begAt && setTotal.endAt)">应用</el-button>
    </div>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import skuSelector from "@/views/assembly/skuSelect";
import request from "@/utils/request";

export default {
  components: { skuSelector },
  mixins: [initData],
  props: {
    isGroup: Boolean,
    distributor: String | Number,
    group: String | Number,
  },
  data() {
    return {
      dialog: false,
      subLoading: false,
      setTotal: {
        begAt: null,
        endAt: null,
      },
      form: {
        begAt: null,
        endAt: null,
        goodsId: null,
      },
      selection: [],
      saving: false,
      deling: false,
      isValid: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      validDate: [],
      rules: [],
    };
  },
  watch: {
    isGroup: "toQuery",
    distributor: "toQuery",
    group: "toQuery",
  },
  computed: {
    editable() {
      if (this.isGroup) return !!this.group;
      else return !!this.distributor;
    },
  },
  methods: {
    beforeInit() {
      this.selection = [];
      if (this.$refs.list) {
        this.$refs.list.clearSelection();
        this.$refs.list.doLayout();
      }
      if (this.editable) {
        this.url = this.isGroup
          ? "api/distributorGroupGoodsSingle"
          : "api/distributorGoodsSingle";
        this.params = Object.assign(
          this.isGroup
            ? { groupId: this.group.id }
            : { buyerId: this.distributor.enterpriseId },
          this.query
        );
        return true;
      } else {
        this.data = [];
        return false;
      }
    },
    handleSelectionChange(sel) {
      this.selection = sel;
    },
    handleGoodsSet(list) {
      if (list && list.length) {
        this.saving = true;
        request({
          url: this.isGroup
            ? "api/distributorGroupGoodsSingle"
            : "api/distributorGoodsSingle",
          method: "post",
          data: Object.assign(
            this.isGroup
              ? { groupId: this.group.id }
              : { buyerId: this.distributor.enterpriseId },
            {
              goodsIds: list.map((o) => {
                return o.id;
              }),
            }
          ),
        })
          .then((res) => {
            this.$notify({
              title: "添加授权商品成功",
              type: "success",
              duration: 2500,
            });
            this.toQuery();
          })
          .finally((_) => {
            this.saving = false;
          });
      }
    },
    handleEdit(data) {
      this.dialog = true;
      this.form = {
        begAt: data.begAt,
        endAt: data.endAt,
        goodsId: data.goodsId,
      };
    },
    // 提交
    doSubmit() {
      // buyerId=xx&goodsId=xx&begAt=xx&endAt=xx
      let data = Object.assign(
        this.form,
        this.isGroup
              ? { groupId: this.group.id }
              : { buyerId: this.distributor.enterpriseId },
        {
          goodsId: this.form.goodsId,
        }
      );
      this.subLoading = true;
      request({
        url: this.isGroup
            ? "api/distributorGroupGoodsSingle/validDate"
            : "api/distributorGoodsSingle/validDate",
        method: "put",
        data,
      })
        .then((res) => {
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500,
          });
          this.subLoading = false;
          this.dialog = false;
          this.init();
        })
        .catch((err) => {
          this.subLoading = false;
          console.log(err.response.data.message);
        });
    },
    // 应用
    handleUse() {
      let data = Object.assign(this.setTotal, 
        this.isGroup
              ? { groupId: this.group.id }
              : { buyerId: this.distributor.enterpriseId }
      );
      request({
        url: this.isGroup
            ? "api/distributorGroupGoodsSingle/validDate"
            : "api/distributorGoodsSingle/validDate",
        method: "put",
        data,
      }).then((res) => {
        this.$notify({
          title: "应用成功",
          type: "success",
          duration: 2500,
        });
        this.init();
      });
    },
    handleGoodsRemove(goods) {
      let ids = [];
      if (goods && goods.goodsId) {
        ids.push(goods.goodsId);
      } else if (this.selection && this.selection.length) {
        ids = this.selection.map((o) => {
          return o.goodsId;
        });
      }
      if (ids && ids.length) {
        this.$confirm(
          goods
            ? `您确定要取消授权商品：${goods.name}[${this.$goodsSpecConvert(
                goods.specs
              )}]吗？`
            : `您确定要取消授权勾选的商品（共${ids.length}个）吗？`,
          "操作确认",
          { type: "warning" }
        ).then((_) => {
          this.deling = true;
          request({
            url: this.isGroup
              ? "api/distributorGroupGoodsSingle/del"
              : "api/distributorGoodsSingle/del",
            method: "post",
            data: Object.assign(
              this.isGroup
                ? { groupId: this.group.id }
                : { buyerId: this.distributor.enterpriseId },
              {
                goodsIds: ids,
              }
            ),
          })
            .then((res) => {
              this.$notify({
                title: "删除授权商品成功",
                type: "success",
                duration: 2500,
              });
              this.toQuery();
            })
            .finally((_) => {
              this.deling = false;
            });
        });
      }
    },
  },
};
</script>